import LinkButton from 'components/shared/link-button'
import { useAppConfig } from 'hooks/features/use-features'
import useTranslation from 'next-translate/useTranslation'
import * as React from 'react'
import { analytics } from 'utils/analytics'

const LoginFormLayout = ({ children }: { children: JSX.Element }) => {
    const { t } = useTranslation('common')
    const { appConfig } = useAppConfig()

    const handleTrack = () => {
        analytics.track('help_click', { type: 'supportPage' })
    }

    return (
        <div>
            <div className="flex items-center justify-center min-h-[95vh]">
                <div className="w-9/12 md:w-6/12 lg:w-3/12 h-full py-4 flex justify-center items-center">
                    {children}
                </div>
            </div>
            <a
                href={
                    appConfig.name === 'ArtQuiz'
                        ? 'https://exuberant-maraca-238.notion.site/Supporto-de3bc38f74134043b7ed3592bd9c5c90?pvs=4'
                        : 'https://systematic-raven.super.site/'
                }>
                <LinkButton onClick={handleTrack} containerClassName="text-center" dataCy="get_support">
                    {t('supportMsg')}
                </LinkButton>
            </a>
        </div>
    )
}

export default LoginFormLayout
