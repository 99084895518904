import * as React from 'react'

import Button, { CustomButtonProps } from '../button'
import { cn } from 'lib/utils'
import CTooltip from '../tooltip'

interface IconButtonProps extends CustomButtonProps {
    tooltip?: string
    variant?: 'default' | 'destructive' | 'outline' | 'secondary' | 'ghost' | 'link' | null | undefined
}

const IconButton = (props: IconButtonProps) => {
    const CButton = (
        <span>
            <Button
                className={cn('px-1.5 mx-1', props.className)}
                style={props.style}
                onClick={props.onClick}
                variant={props.variant ?? 'ghost'}>
                {props.children}
            </Button>
        </span>
    )
    return props.tooltip ? <CTooltip content={props.tooltip} trigger={CButton} /> : CButton
}

export default IconButton
