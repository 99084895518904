import * as T from 'components/ui/typography'
import * as React from 'react'

import { cn } from 'lib/utils'
interface LinkButtonProps {
    dataCy: string
    children: JSX.Element
    onClick?: () => void
    containerClassName?: string
    textClassName?: string
}

const LinkButton = React.memo<LinkButtonProps>(({ dataCy, children, onClick, containerClassName, textClassName }) => {
    return (
        <div onClick={onClick ?? undefined} className={cn(containerClassName, 'cursor-pointer')}>
            <T.Small className={cn(textClassName, 'text-primary font-bold capitalize underline')} data-cy={dataCy}>
                {children}
            </T.Small>
        </div>
    )
})
export default LinkButton
