import * as React from 'react'
import * as firebaseSdk from 'sdk/firebase'
import * as TestySdk from 'sdk/testy-web-sdk'
import * as T from 'components/ui/typography'

import FormTextField from 'components/shared/form-field-input'
import useTranslation from 'next-translate/useTranslation'

import CustomModal from 'components/shared/custom-Modal'
import Button from 'components/shared/button'

interface RecoveryPasswordModalProps {
    trigger: JSX.Element
    identifier: string
    setIdentifier: (e: string) => void
}

const RecoveryPasswordModal = (props: RecoveryPasswordModalProps) => {
    const [error, setError] = React.useState()
    const [recoverMailSent, setRecoverMailSent] = React.useState(false)
    const { trigger, identifier, setIdentifier } = props

    const handleRecoverPass = async () => {
        try {
            await TestySdk.checkUserExistance({ email: identifier })
            await firebaseSdk.recoverPassword(identifier)
        } catch (e) {
            setError(
                //@ts-expect-error "TODO: RESOLVE TYPES"
                e.response.status ? e.response.status : 'Non è stato possibile recuperare la password',
            )

            throw e
        } finally {
            setRecoverMailSent(true)
        }
    }

    const { t } = useTranslation('common')

    return (
        <CustomModal
            title={t('passwordBack')}
            trigger={trigger}
            actions={
                !recoverMailSent ? (
                    <div>
                        <FormTextField error="" placeholder={t('passwordMail')} onChangeFn={(e) => setIdentifier(e)} />
                        <T.Muted>{t('passwordLink')}</T.Muted>

                        <Button className="mt-6 w-full" onClick={() => handleRecoverPass()}>
                            Conferma
                        </Button>
                    </div>
                ) : (
                    <div className="text-center">
                        {error ? (
                            <T.Large className="text-error">{error}</T.Large>
                        ) : (
                            <>
                                {' '}
                                <T.Large>{t('linkSent')}</T.Large>
                                <T.Small>{t('linkSentMsg')}</T.Small>
                            </>
                        )}
                    </div>
                )
            }
        />
    )
}

export default RecoveryPasswordModal
