import React from 'react'
import * as T from 'components/ui/typography'

import AppLogo from 'components/shared/logo'
import SocialButton from 'components/shared/social-button'
import useTranslation from 'next-translate/useTranslation'

const LoginWelcomeMessage = React.memo(() => {
    const { t } = useTranslation('common')
    return (
        <div>
            <AppLogo />

            <T.H2 className="mt-3 mb-1  font-regular text-start">
                <>{t('welcome')} 👋</>
            </T.H2>

            <T.H3 className="font-light mb-6 text-lg text-start">{t('welcomeMessage')}</T.H3>

            <SocialButton social="Google" actionType="Login" />
            {/* <SocialButton social="Apple" signMode="Login" /> */}

            <T.Muted className="py-4">{t('logWith')}</T.Muted>
        </div>
    )
})

export default LoginWelcomeMessage
